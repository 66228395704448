<template>
  <div>
    <v-simple-table v-if="words.length > 0">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Palabra
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(word, i) in words"
            :key="i"
        >
          <td>{{ word }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p  v-else class="text-h5 text--primary text-justify mt-3">
      No tiene palabras guardadas, seleccione alguna del texto.
    </p>
  </div>

</template>

<script>
export default {
  props: {
    words: {
      type: Array
    }
  },
}
</script>